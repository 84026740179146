import { Box, Typography } from "@mui/material";
import React from "react";

export default function PrivacyPolicy(props) {
  return (
    <Box color="#FFFFFF">
      <Typography align="center" variant="h1" fontSize="30px" fontWeight="bold">
        Privacy Policy
      </Typography>
      <ul>
        <li>
          <h3>1. Introduction</h3>
          <p>
            Welcome to the Garba Mandal web and mobile application ("the App")
            provided by GV Technolab ("we," "our," or "us"). This Privacy Policy
            outlines our practices regarding the collection, use, and disclosure
            of personal information when you use our App.
          </p>
        </li>
        <li>
          <h3>2. Information We Collect</h3>
          <p>
            a. Personal Information: When you register or use our App, we may
            collect personally identifiable information, such as names, email
            addresses, and phone numbers. b. Non-Personal Information: We may
            collect non-personal information, such as device information, IP
            addresses, and usage statistics, to improve our services and enhance
            user experience.
          </p>
        </li>
        <li>
          <h3>3. How We Use Your Information We use the collected</h3>
          <p>
            information for the following purposes: a. To provide and maintain
            the App. b. To notify you about changes to our App. c. To improve
            the App's functionality and user experience. d. To respond to your
            inquiries and provide user support. e. To send periodic emails, such
            as newsletters and updates, if you have subscribed to them.
          </p>
        </li>
        <li>
          <h3>4. Data Security We prioritize</h3>
          <p>
            the security of your data and employ industry-standard measures to
            protect it from unauthorized access, disclosure, alteration, and
            destruction.
          </p>
        </li>
        <li>
          <h3>
            5. Third-Party Services Our App may contain links to third-party
            websites or services.
          </h3>
          <p>
            Please note that we are not responsible for the privacy practices of
            these third parties. We recommend reviewing their privacy policies.
          </p>
        </li>
        <li>
          <h3>6. Changes to This</h3>
          <p>
            Privacy Policy We may update our Privacy Policy from time to time.
            We will notify you of any changes by posting the new Privacy Policy
            on this page. You are advised to review this Privacy Policy
            periodically for any changes.
          </p>
        </li>

        <li>
          <h3>
            7. Contact Us If you have any questions or concerns about this
            Privacy Policy,
          </h3>
          <p>
            please contact us at <a href="mailto:contact@gvtechnolab.in" >contact@gvtechnolab.in</a>. By using
            our App, you agree to the terms outlined in this Privacy Policy.
          </p>
        </li>
      </ul>
    </Box>
  );
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { Link, useNavigate } from "react-router-dom";
// import { SwipeableDrawer } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupListItems from "./GroupListItems";
import ListIcon from "@mui/icons-material/List";
import AppsIcon from "@mui/icons-material/Apps";
import ForumIcon from "@mui/icons-material/Forum";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    display: "block",
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    display: "none",
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const variantsSidebarContainer = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SideNav(props) {
  const navigate = useNavigate();
  // const theme = useTheme();
  const handleDrawerClose = () => {
    props?.setOpen(false);
  };
  const [isSignedIn, setIsSignIn] = React.useState(false);
  React.useEffect(() => {
    let user = localStorage.getItem("user", true);
    setIsSignIn(Boolean(user));
  }, []);

  return (
    <>
      <motion.div variants={variants}>
        {/* <SwipeableDrawer
       anchor={"left"}
       open={props?.open}
       onClose={() => props?.setOpen(false)}
       onOpen={() => props?.setOpen(true)}
     > */}
        <DrawerHeader>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <motion.div
          variants={variantsSidebarContainer}
          className="sidebar-container"
        >
          <List>
            <GroupListItems
              onItemClick={handleDrawerClose}
              items={[
                { label: "Home", link: "/", icon: <HomeIcon /> },
                {
                  label: "અનુક્રમણિકા",
                  link: "/charslist",
                  icon: <AppsIcon />,
                },
                { label: "બધી સૂચિ", link: "/list", icon: <ListIcon /> },
                { label: "આરતી", link: "/list/1", icon: <ListAltIcon /> },
                { label: "ગરબા", link: "/list/3", icon: <ListAltIcon /> },
                { label: "સ્તુતિ", link: "/list/6", icon: <ListAltIcon /> },
                { label: "છંદ", link: "/list/2", icon: <ListAltIcon /> },
                { label: "Groups", link: "/groups", icon: <ListAltIcon /> },
                // { label: "Videos", link: "/videos", icon: <ListAltIcon /> },
                // { label: "SongList", link: "/list", icon: <ListAltIcon /> },
                // { label: "Player", link: "/player", icon: <PlayCircleOutlineIcon /> },
              ]}
            />
            {isSignedIn && (
              <GroupListItems
                onItemClick={handleDrawerClose}
                items={[
                  {
                    label: "Add Song",
                    link: "/addSong",
                    icon: <LibraryAddIcon />,
                  },
                ]}
              />
            )}
          </List>
          <Divider />
          {/* {isSignedIn && (
            <List>
              <GroupListItems
                onItemClick={handleDrawerClose}
                items={[
                  {
                    label: "પ્રશાદ થાળ દાતાશ્રી ની યાદી",
                    link: "/prasadthal",
                    icon: <ListAltIcon />,
                  },
                ]}
              />

              <GroupListItems
                onItemClick={handleDrawerClose}
                items={[
                  {
                    label: "Add Prasad Thal Entry",
                    link: "/addprasadthal",
                    icon: <LibraryAddIcon />,
                  },
                ]}
              />
            </List>
          )} */}
          {/* <Divider />
          <List>
            {isSignedIn ? (
              <GroupListItems
                onItemClick={handleDrawerClose}
                items={[
                  {
                    label: "Feedback",
                    link: "/feedbacks",
                    icon: <ForumIcon />,
                  },
                ]}
              />
            ) : (
              ""
            )}
            <GroupListItems
              onItemClick={handleDrawerClose}
              items={[
                {
                  label: "Add Feedback",
                  link: "/feedbacks/add",
                  icon: <AddCommentIcon />,
                },
              ]}
            />
          </List> */}
          <Divider />
          <List>
            <GroupListItems
              onItemClick={handleDrawerClose}
              items={[
                { label: "Privacy Policy", link: "/privary-policy", icon: <HelpCenterIcon /> },
                { label: "Help", link: "/help", icon: <HelpCenterIcon /> },
              ]}
            />

            {isSignedIn ? (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    navigate(0);
                  }}
                  component={Link}
                >
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    props?.setOpen(false);
                  }}
                  to={"/login"}
                  component={Link}
                >
                  <ListItemIcon>
                    <LoginIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Login"} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </motion.div>
        {/* </SwipeableDrawer> */}
      </motion.div>
    </>
  );
}

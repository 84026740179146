import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
const ListItemMotion = motion(ListItem);

const GroupListItems = (props) => {
  return (
    <>
      {[...props?.items].map((item, index) => (
        <ListItemMotion
          key={item?.label}
          className={props.className}
          sx={props.sx ? props.sx : {}}
          disablePadding
          {...props.motions}
        >
          <ListItemButton
            to={item?.link}
            component={Link}
            onClick={() => {
              props?.onItemClick();
            }}
          >
            <ListItemIcon>{item?.icon}</ListItemIcon>
            <ListItemText primary={item?.label} />
          </ListItemButton>
        </ListItemMotion>
      ))}
    </>
  );
};

export default GroupListItems;

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, Navigate } from "react-router-dom";
import { API_CONSTS } from "../../config/constants";
import { post } from "../../config/http-common";

export default function Login() {
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [isSignedIn, setIsSignIn] = React.useState(false);
  React.useEffect(() => {
    let user = localStorage.getItem("user", true);
    setIsSignIn(Boolean(user));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    const data = new FormData(event.currentTarget);
    const postData = {
      username: data.get("username"),
      password: data.get("password"),
    };
    console.log("postData", postData)
    await post(API_CONSTS?.LOGIN_USER, postData)
      // await post("users/login", postData)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.data?.token) {
            localStorage.setItem("token", res?.data?.data?.token);
          }
          if (res?.data) {
            localStorage.setItem("user", JSON.stringify(res?.data));
          }
          navigate(0);
        }
      })
      .catch((error) => {
        console.log("errors", error);
        setError(error?.response?.data?.status);
      });
  };

  if (isSignedIn) {
    return <Navigate to="/" replace />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          p: "10px",

          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error !== "" && (
          <Typography component="small" color="red">
            {error}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username_1"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Container>
  );
}

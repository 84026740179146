import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import Home from "../Pages/Home/Home";
import Help from "../Pages/Help/Help";
import PlayerCard from "../Components/PlayerCard/PlayerCard";
import ErrorPage from "../Pages/ErrorPage";
// import SongList from "../Pages/SongList/SongList";
import ViewSong from "../Pages/Songs/ViewSong";
import SongTable from "../Pages/Songs/SongTable";
import AddSong from "../Pages/Songs/AddSong";
// import PrasadThal from "../Pages/PrasadThal/PrasadThal";
import Login from "../Pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
// import AddPrasadthal from "../Pages/PrasadThal/AddPrasadthal";
import EditSong from "../Pages/Songs/EditSong";
// import EditPrasadthal from "../Pages/PrasadThal/EditPrasadthal";
import AlfaCategory from "../Pages/Songs/AlfaCategory";
import Book from "../Pages/BookView/Book";
import GroupsList from "../Pages/Groups/GroupsList";
import AddGroup from "../Pages/Groups/AddGroup";
import GroupSongList from "../Pages/Groups/GroupSongList";
import FeedbackList from "../Pages/Feedback/FeedbackList";
import AddFeedback from "../Pages/Feedback/AddFeedback";
// import Videos from "../Pages/Videos/Videos";
import PrivacyPolicy from "../Pages/Privacy/PrivacyPolicy";
import Register from "../Pages/Auth/Register";
function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/addSong"
          element={
            <ProtectedRoute>
              <AddSong />
            </ProtectedRoute>
          }
        />
        <Route path="/charslist" element={<AlfaCategory />} />
        <Route path="/list" element={<SongTable />} />
        <Route path="/list/view/:songId" element={<ViewSong />} />
        <Route path="/song/view/:songId" element={<ViewSong />} />
        <Route
          path="/song/edit/:songId"
          element={
            <ProtectedRoute>
              <EditSong />
            </ProtectedRoute>
          }
        />
        <Route path="/list/:type" element={<SongTable />} />
        <Route path="/player" element={<PlayerCard />}>
          <Route path=":songId" element={<PlayerCard />} />
        </Route>
        <Route path="/help" element={<Help />} />
        {/* <Route
          path="/prasadthal/edit/:id"
          element={
            <ProtectedRoute>
              <EditPrasadthal />
            </ProtectedRoute>
          }
        /> */}
        {/* <Route
          path="/prasadthal"
          element={
            <ProtectedRoute>
              <PrasadThal />
            </ProtectedRoute>
          }
        /> */}
        {/* <Route
          path="/addprasadthal"
          element={
            <ProtectedRoute>
              <AddPrasadthal />
            </ProtectedRoute>
          }
        /> */}
        <Route path="/groups" element={<GroupsList />} />
        <Route path="/groups/view/:id" element={<GroupSongList />} />
        <Route
          path="/groups/add"
          element={
            <ProtectedRoute>
              <AddGroup />
            </ProtectedRoute>
          }
        >
          <Route
            path=":id"
            element={
              <ProtectedRoute>
                <AddGroup />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/book" element={<Book />} />
        {/* <Route path="/videos" element={<Videos />} /> */}
        <Route
          path="/feedbacks"
          element={
            <ProtectedRoute>
              <FeedbackList />
            </ProtectedRoute>
          }
        />
        <Route path="/feedbacks/add" element={<AddFeedback />} />
        <Route path="/privary-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default Router;

import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "35px",
  color: theme.palette.text.secondary,
}));

export default function AlfaCategory(props) {
  const tracksData = useSelector((state) => state.songs);
  const [chars, setChars] = React.useState([]);
  const getListFirstChars = React.useCallback(() => {
    var sections = tracksData
      .map(function (item) {
        return item.name.substring(0, 1);
      })
      .filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });
    return sections;
  }, [tracksData]);
  React.useEffect(() => {
    const characters = getListFirstChars();
    setChars(characters.sort());
  }, [tracksData, getListFirstChars]);
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid key={"01"} item xs={12}>
        <Link to={"/list"}>
          <Item>બધી સૂચિ</Item>{" "}
        </Link>
      </Grid>

      {chars.map((item, index) => {
        return (
          <Grid key={index} item xs={6} md={4} lg={2}>
            <Link to={"/list?startWith=" + item}>
              <Item>{item}</Item>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
}

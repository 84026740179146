import { Box } from "@mui/material";
import { Container } from "@mui/system";

function Help() {
  const appdata = {
    name: "Garba Mandal Budhel",
    version: "1.0.0",
    uses: "to store songs at one place and can search sort and view songs any time",
    developBy: "Gvtechnolab",
    website: "gvtechnolab.in",
    contactEmail: "gvtechnolab@gmail.com",
    contactPhone: "9537779278",
  };
  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          p: "10px",
        }}
      >
        {Object.keys(appdata)?.map((item) => {
          return (
            <p key={item}>
              {item} :- <strong>{appdata[item]}</strong>
            </p>
          );
        })}
      </Box>
    </Container>
  );
}

export default Help;

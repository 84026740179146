import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, ListItemSecondaryAction, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_CONSTS } from "../../config/constants";
import { get, post } from "../../config/http-common";
import { groupList } from "../../Redux/groupsSlice";

export default function GroupsList(props) {
  const user = localStorage.getItem("user");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false)
  const groupsData = useSelector((state) => state.groups);

  const fetchGroups = React.useCallback(async () => {
    const newData = await get(API_CONSTS?.GROUPS_LIST);
    dispatch(groupList(newData?.data));
  }, [dispatch]);

  React.useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const handleEditClick = (e, data) => {
    e.preventDefault();
    navigate(`/groups/add/${data?.id}`);
  };

  const handleDeleteClick = async (id) => {
    console.log("id", id);
    // setLoading(true);
    try {
      // await post(API_CONSTS?.GROUPS + id, {
      //   method: "DELETE",
      // }).then((res) => {
      await fetch(
        process.env.REACT_APP_API_FILE_READER_BASE_URL +
          "/" +
          API_CONSTS?.GROUPS +
          +id,
        {
          method: "DELETE",
        }
      ).then((res) => {
        console.log("res", res);
        fetchGroups();
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    // setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        component={Link}
        to={"/groups/add"}
      >
        Add Group
      </Button>
      <List>
        {groupsData?.length > 0 ? (
          groupsData?.map((item) => {
            if (item?.id) {
              return (
                <>
                  <ListItem
                    alignItems="flex-start"
                    flexWrap="wrap"
                    disableGutters
                    sx={{
                      backgroundColor: "#FFFFFF",
                      marginBottom: "10px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      borderRadius: "10px",
                      px: "10px",
                    }}
                  >
                    <>
                      <ListItemText
                        primary={
                          <>
                            <Link to={`/groups/view/${item?.id}`}>
                              <Typography
                                fontSize="18px"
                                display="inline"
                                fontWeight="600"
                              >
                                {" "}
                                {item?.name}
                              </Typography>
                            </Link>
                          </>
                        }
                        // secondary={
                        //   <React.Fragment>{`${item?.summary}`}</React.Fragment>
                        // }
                      />
                      <ListItemSecondaryAction
                        sx={{
                          position: "relative",
                          transform: "none",
                          left: "0",
                          top: "auto",
                        }}
                      >
                        <Button
                          size="small"
                          varient="text"
                          to={`/groups/view/${item?.id}`}
                          component={Link}
                        >
                          <VisibilityIcon sx={{ marginRight: "5px" }} /> જુઓ
                        </Button>
                        {user && user !== "undefined" && (
                          <>
                            <Button
                              size="small"
                              varient="text"
                              // to={`/groups/add/${item?.id}`}
                              onClick={(e) => {
                                handleEditClick(e, item);
                              }}
                              component={Link}
                            >
                              <EditIcon sx={{ marginRight: "5px" }} /> સુધારો
                            </Button>
                            <Button
                              size="small"
                              varient="text"
                              // to={`/groups/add/${item?.id}`}
                              onClick={() => {
                                handleDeleteClick(item?.id);
                              }}
                              component={Link}
                            >
                              <DeleteIcon sx={{ marginRight: "5px" }} />
                              દૂર કરો
                            </Button>
                          </>
                        )}
                      </ListItemSecondaryAction>
                    </>
                  </ListItem>
                  <Divider component="li" />
                </>
              );
            } else {
              return "";
            }
          })
        ) : (
          <ListItem
            alignItems="flex-start"
            flexWrap="wrap"
            disableGutters
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            No Groups found
          </ListItem>
        )}
      </List>
    </>
  );
}

import React from "react";
import List from "@mui/material/List";
import ListIcon from "@mui/icons-material/List";
import GroupListItems from "../../Components/SideNav/GroupListItems";
import AppsIcon from "@mui/icons-material/Apps";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Link } from "@mui/material";
import { motion } from "framer-motion";
const ListMotion = motion(List);

const itemVariant = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: 100 },
};
function Home() {
  // const rootLoading = useSelector((state) => state.rootLoading);
  const ListItemStyle = {
    border: "1px solid",
    borderColor: "primary.main",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    marginBottom: "10px",
    alignitems: "center",
    fontSize: "20px",
  };
  // if (rootLoading.value == true) {
  //   return <Splash />;
  // }
  return (
    <>
      <ListMotion
        alignItems="center"
        sx={{ md: { width: "500px" }, margin: "auto", marginTop: "10px" }}
        initial="hidden"
        animate="visible"
        stagger
        viewport={{ once: true, amount: 1 }}
      >
        <GroupListItems
          onItemClick={() => {}}
          sx={ListItemStyle}
          motions={{ variants: itemVariant }}
          items={[
            { label: "અનુક્રમણિકા", link: "/charslist", icon: <AppsIcon /> },
            { label: "બધી સૂચિ", link: "/list", icon: <ListIcon /> },
            { label: "આરતી", link: "/list/1", icon: <ListAltIcon /> },
            { label: "ગરબા", link: "/list/3", icon: <ListAltIcon /> },
            { label: "સ્તુતિ", link: "/list/6", icon: <ListAltIcon /> },
            { label: "છંદ", link: "/list/2", icon: <ListAltIcon /> },
            { label: "Most Viewed", link: "/list?q=top10", icon: <ListAltIcon /> },
            { label: "Groups", link: "/groups", icon: <ListAltIcon /> },
            { label: "Book View", link: "/book", icon: <ListAltIcon /> },
            // { label: "Videos", link: "/videos", icon: <ListAltIcon /> },
          ]}
        />
      </ListMotion>
    </>
  );
}

export default Home;

import { createSlice } from "@reduxjs/toolkit";

const groupsSlice = createSlice({
  name: "groups",
  initialState: [],
  reducers: {
    groupList(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export const { groupList } = groupsSlice.actions;
export default groupsSlice.reducer;

import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Button, ListItemSecondaryAction, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { get, post } from "../../config/http-common";
import { API_CONSTS } from "../../config/constants";
import { songList } from "../../Redux/songsSlice";
import { useDispatch, useSelector } from "react-redux";
export const ListItemMotion = motion(ListItem);
export const ListItemTextMotion = motion(ListItemText);
export const ListItemSecondaryActionMotion = motion(ListItemSecondaryAction);
export const cardVariants = {
  offscreen: {
    x: 30,
  },
  onscreen: {
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export default function SongList(props) {
  const user = localStorage.getItem("user");
  const tracksData = useSelector((state) => state.songs);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchSongs = React.useCallback(async () => {
    const newData = await get(API_CONSTS?.SONGS_LIST);
    dispatch(songList(newData?.data));
  }, [dispatch]);

  const visitCountIncrement = async (id) => {
    const currentTrack = tracksData?.find(
      (item) => String(item.id) === String(id)
    );
    const viewCountData = {
      id: id,
      view_count: parseInt(currentTrack?.view_count) + 1,
    };
    await post(`${API_CONSTS.VIEW_COUNT}/${id}`, viewCountData).then((res) => {
      fetchSongs();
      navigate(`/list/view/${res?.data?.songId}`);
    }).catch(() => {
      navigate(`/list/view/${id}`);
    });
  };

  return (
    <>
      <List>
        {props?.tracks?.length > 0 ? (
          props?.tracks?.map((item) => {
            if (item?.id) {
              return (
                <>
                  <ListItemMotion
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 1 }}
                    key={`songitem-${item.id}`}
                    alignItems="flex-start"
                    flexWrap="wrap"
                    disableGutters
                    sx={{
                      backgroundColor: "#FFFFFF",
                      marginBottom: "10px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      borderRadius: "10px",
                      px: "10px",
                    }}
                  >
                    <>
                      <ListItemTextMotion
                        variants={cardVariants}
                        primary={
                          <>
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                visitCountIncrement(item?.id);
                              }}
                            >
                              <Typography
                                fontSize="18px"
                                display="inline"
                                fontWeight="600"
                              >
                                {item?.id} - {item?.name}{" "}
                              </Typography>
                              {item?.nameen && <small>({item?.nameen})</small>}
                            </Link>
                          </>
                        }
                        secondary={
                          <React.Fragment>{`${item?.summary}`}</React.Fragment>
                        }
                      />
                      <ListItemSecondaryActionMotion
                        variants={cardVariants}
                        sx={{
                          position: "relative",
                          transform: "none",
                          left: "0",
                          top: "auto",
                        }}
                      >
                        <Button
                          size="small"
                          varient="text"
                          to={`/list/view/${item?.id}`}
                          component={Link}
                        >
                          <VisibilityIcon sx={{ marginRight: "5px" }} /> જુઓ
                        </Button>
                        {user && user !== "undefined" && (
                          <Button
                            size="small"
                            varient="text"
                            to={`/song/edit/${item?.id}`}
                            component={Link}
                          >
                            <EditIcon sx={{ marginRight: "5px" }} /> સુધારો
                          </Button>
                        )}
                      </ListItemSecondaryActionMotion>
                    </>
                  </ListItemMotion>
                  <Divider component="li" />
                </>
              );
            } else {
              return "";
            }
          })
        ) : (
          <ListItem
            alignItems="flex-start"
            flexWrap="wrap"
            disableGutters
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            No Track found
          </ListItem>
        )}
      </List>
      <Outlet />
    </>
  );
}

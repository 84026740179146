import { createSlice } from "@reduxjs/toolkit";

const songsSlice = createSlice({
  name: "songs",
  initialState: [],
  reducers: {
    songList(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export const { songList } = songsSlice.actions;
export default songsSlice.reducer;

import { ImageList, ImageListItem } from "@mui/material";
import React from "react";
import Lightbox from "react-awesome-lightbox";

const ImageGallary = (props) => {
  const [showLightBox, setShowLightBox] = React.useState(false);

  return (
    <>
      {props?.previewFile?.length > 0 && props?.previewFile?.length === 1 ? (
        <ImageList cols="1">
          {props?.previewFile?.map((item, index) => (
            <ImageListItem key={item}>
              <img
                src={item}
                srcSet={item}
                alt={item}
                loading="lazy"
                onClick={() => setShowLightBox(index)}
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        props?.previewFile?.length > 0 && (
          <ImageList>
            {props?.previewFile.map((item, index) => (
              <ImageListItem key={item}>
                <img
                  src={item}
                  srcSet={item}
                  alt={item}
                  loading="lazy"
                  onClick={() => setShowLightBox(index)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )
      )}
      {showLightBox !== false && props?.previewFile?.length > 0 && (
        <Lightbox
          onClose={() => setShowLightBox(false)}
          startIndex={showLightBox}
          images={[
            ...props?.previewFile?.map((item) => {
              return { url: item };
            }),
          ]}
        />
      )}
    </>
  );
};
export default ImageGallary;

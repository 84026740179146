import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { API_CONSTS } from "../../config/constants";
import { get } from "../../config/http-common";

export default function FeedbackList(props) {
  const [feedbackListData, setFeedbackList] = React.useState([]);

  const fetchList = React.useCallback(async () => {
    const newData = await get(API_CONSTS?.FEEDBACK_LIST);
    setFeedbackList(newData?.data);
  }, []);

  React.useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>
      <List>
        {feedbackListData?.length > 0 ? (
          feedbackListData?.map((item) => {
            if (item?.id) {
              return (
                <>
                  <ListItem
                    alignItems="flex-start"
                    flexWrap="wrap"
                    disableGutters
                    sx={{
                      backgroundColor: "#FFFFFF",
                      marginBottom: "10px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      borderRadius: "10px",
                      px: "10px",
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            fontSize="18px"
                            display="inline"
                            fontWeight="600"
                          >
                            {item?.name}
                          </Typography>
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          {`Mo: ${item?.mobile}, Email: ${item?.email}`}
                          <p>{item?.comment}</p>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </>
              );
            } else {
              return "";
            }
          })
        ) : (
          <ListItem
            alignItems="flex-start"
            flexWrap="wrap"
            disableGutters
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            No Feedback found
          </ListItem>
        )}
      </List>
    </>
  );
}

export const tracks = [
  {
    id: "1",
    name: "શ્રી હનુમાન ચાલીસા",
    artist: "",
    firstLineLyrics: `શ્રી ગુરુ ચારણ સરોજ રાજ નિજ મનુ મુકુર સુધારી ।
    બારણું બિમલ જાસુ જો દાયકુ ફળ ચારી ॥`,
    lyrics: `॥ દોહા ॥
    શ્રી ગુરુ ચારણ સરોજ રાજ નિજ મનુ મુકુર સુધારી ।
    બારણું બિમલ જાસુ જો દાયકુ ફળ ચારી ॥
    
    બુદ્ધિ હીન તહુ જાનિકે સુમેરોઃ પવન કુમાર ।
    બળ બુદ્ધિ બીડ્યા દેઉ મોહી કરાયુ કલેસ બિકાર ॥
    ॥ ચૌપાઈ ॥
    જાય હનુમાન જ્ઞાન ગુન સાગર ।
    જાય કપીસ તિહું લોક ઉજાગર ॥०१॥
    
    રામ દૂત અતુલિત બળ ધામા ।
    અંજની પુત્ર પવન સુત નામા ॥०२॥
    
    મહાબીર બિક્રમ બજરંગી ।
    કુમતિ નિવાર સુમતિ કે સંગી ॥०३॥
    
    કંચન બરન બિરાજ સુબેસા ।
    કાનન કુંડળ કુંચિત કેસા ॥०४॥
    
    હાત બજ્ર ઔર ધ્વજા બિરાજે ।
    કાંધે મુંજ જનેઉ સાંજે ॥०५॥
    
    સંકર સુવન કેસરી નંદન ।
    તેજ પ્રતાપ મહા જગ બંદન ॥०६॥
    
    બીડ્યાંબાન ગુણી અતિ ચતુર ।
    રામ કાજ કરિબે કો આતુર ॥०७॥
    
    પ્રભુ ચરિત્ર સુનિબે કો રસિયા ।
    રામ લખન સીતા મન બસિયા ॥०८॥
    
    સૂક્ષ્મ રૂપ ધરી સિયહિ દિખાવા ।
    બિકટ રૂપ ધારી લંક જરાવા ॥०९॥
    
    ભીમ રૂપ ધરી અસુર સહારે ।
    રામચંદ્ર કે કાજ સવારે ॥१०॥
    
    લાયે સંજીવન લખન જિયાયે ।
    શ્રી રઘુબીર હરષિ ઉર લાયે ॥११॥
    
    રઘુપતિ કીન્હી બહુત બધાયે ।
    તુમ મમ પ્રિયઃ ભારત સમ ભાઈ ॥१२॥
    
    સહસ બદન તુમ્હરો જસ ગાવે ।
    અસ કહી શ્રીપતિ કંઠ લગાવે ॥१३॥
    
    સનકાદિક બ્રમ્હાદિ મુનીસા ।
    નારદ સરળ સહીત અહીસા ॥१४॥
    
    જામ કુબેર દિગપાલ જાહાંતે ।
    કબી કોબિન્ધ કહી સખે કહાંતે ॥१५॥
    
    તુમ ઉપકાર સુગ્રીવહિં કીન્હા ।
    રામ મિલાય રાજ પદ દીન્હા ॥१६॥
    
    તુમ્હરો મંત્ર વિભીષણ માના ।
    લંકે સ્વર ભય સબ જગ જાના ॥१७॥
    
    જગ સહસ્ત્ર જોજન પાર ભાનુ ।
    લીલ્યો તાહી મધુર ફળ જાણું ॥१८॥
    
    પ્રભુ મુદ્રિકા મૈલી મુખ માહી ।
    જલ્દી લાગી ગયે અચરજ નાહી ॥१९॥
    
    દુર્ગમ કાજ જગત કે જેતે ।
    સુગમ અનુગ્રહ તુમ્હરે તેતે ॥२०॥
    
    રામ દુઆરે તુમ રખવારે ।
    હોત ન અડયના બેનું પૈસારે ॥२१॥
    
    સબ સુખ લહે તુમ્હારી સરના ।
    તુમ રાકચક કહું કો દરના ॥२२॥
    
    આપન તેજ સમ્હારો આપે ।
    ટીનો લોક હાંક તેહ કાપે ॥२३॥
    
    ભૂત પિશાચય નિકટ નહિ આવે ।
    મહાબીર જબ નામ સુનાવે ॥२४॥
    
    નાસે રોગ હરે સબ પીર ।
    જપ્ત નિરંતર હનુમત બિરા ॥२५॥
    
    સંકટ તેહ હનુમાન છુડાવે ।
    મન ક્રમ બચન ધ્યાન જબ લાવે ॥२६॥
    
    સબ પાર રામ પપસ્વી રાજા ।
    ટીન કે કાજ સકલ તુમ સઝા ॥२७॥
    
    ઔર મનોરથ જો કોઈ લાવે ।
    સોઈ અમિત જીવન ફલ પાવે ॥२८॥
    
    ચારો જુગ પરતાપ તુમ્હારા ।
    હૈ પરસિદ્ધ જગત ઉજિયારા ॥२९॥
    
    સાધુ સંત કે તુમ રખવારે ।
    અસુર નિકાનંદન રામ દુલારે ॥३०॥
    
    અષ્ટ સીધી નવ નિધિ કે દાતા ।
    અસ બર દિન જાનકી માતા ॥३१॥
    
    રામ રસાયન તુમ્હારે પાસા ।
    સદા રહો રઘુપતિ કે દાસા ॥३२॥
    
    તુમ્હરે ભજન રામ કો પાવે ।
    જન્મ જન્મ કે દુખ બિસરાવે ॥३३॥
    
    અંત કાળ રઘુબર પૂર જાયી ।
    જહાં જન્મ હરિ ભક્ત કહાયી ॥३४॥
    
    ઔર દેવતા ચિઠ ન ધારયિ ।
    હનુમત સેહી સર્બ સુખ કરયિ ॥३५॥
    
    સંકટ કાટે મિટે સબ પેરા ।
    જો સુમીરે હનુમ્ત બલબીરા ॥३६॥
    
    જાય જાય જાય હનુમાન ગોસાઈ ।
    કૃપા કરઉ ગુરુ દેવકી નઈ ॥३७॥
    
    જો સત બાર પાઠ કર કોઈ ।
    છૂટહિ બંદી મહા સુખ હોઈ ॥३८॥
    
    જો યહ પઢે હનુમાન ચાલીસા ।
    હોય સીધી સાખી ગૌરીસા ॥३९॥
    
    તુલસીદાસ સદા હરિ ચેરા ।
    કીજે નાથ હૃદય મહ ડેરા ॥४०॥
    ॥ દોહા ॥
    પવનતનય સંકટ હરન મંગલ મૂર્તિ રૃપ ।
    રામ લખન સીતા સહીત હૃદય બસઉ સુર ભૂપ ॥
    ॥ જાય-ઘોષ ॥
    બોલ બજરંગબળી કી જય ।
    પવન પુત્ર હનુમાન કી જય ॥`,
    cover:
      "https://raw.githubusercontent.com/muhammederdem/mini-player/master/img/1.jpg",
    source: "",
    url: "https://www.youtube.com/watch?v=z3wAjJXbYzA",
    favorited: false,
  },
  {
    id: "2",
    name: "શ્રી બહુચર માની સ્તુતિ",
    artist: "",
    firstLineLyrics: `આધશક્તિ તુજને નમું રે , બહુચર ગણપત લાગુ પાય ; <br>દીન જાણી દયા કરો રે , બહુચરી મુખે માગું તે થાય .`,
    lyrics: `<span style="font-size: large;">આધશક્તિ તુજને નમું રે , બહુચર ગણપત લાગુ પાય ; <br>દીન જાણી દયા કરો રે , બહુચરી મુખે માગું તે થાય . <br>વાણી આપોને પરમેશ્વરી , બહુચરી ગુણ તમારા ગાય ; <br>ચોસઠ બે’ની માડી સામટી રે , બહુચરી માન સરોવર જાય . <br>માન સરોવર ઝીલતાં રે બહુચરી કરતાં તે નિત વિલાસ ; <br>શંખલપુર ગામ સોહામણું રે , બહુચરી ત્યાં તમારો વાસ . <br>સર્વ મળી કીધી સ્થાપના રે , બહુચરી સોનું ઘડે સોનાર , <br>ઘકિયાં બાજુબંધ બેરખાં રે , બહુચરી ઘડિયા એકાવળ હાર ,<br>&nbsp;પ્રથમ ચરિત્ર તમે કર્યા રે , બહુચરી માર્યો તે મહિષાસુર ;</span>`,
    cover:
      "https://raw.githubusercontent.com/muhammederdem/mini-player/master/img/8.jpg",
    source:
      "https://raw.githubusercontent.com/muhammederdem/mini-player/master/mp3/8.mp3",
    url: "https://www.youtube.com/watch?v=00-Rl3Jlx-o",
    favorited: false,
  },
];

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_CONSTS } from "../../config/constants";
import { get, post } from "../../config/http-common";
import { groupList } from "../../Redux/groupsSlice";
// import MultiSelectInput from "../../Components/MultiSelectInput";
// import MultipleSelectTags from "../../Components/Form/MultipleSelectTags";
import SingleSelectTags from "../../Components/Form/SingleSelectTags";

function AddGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tracksData = useSelector((state) => state.songs);
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const GroupData = useSelector((state) => state.groups);
  const [allInputs, setAllInputs] = useState({
    name: "",
  });

  const [inputFields, setInputFields] = useState([]);

  const fetchAllGroups = React.useCallback(async () => {
    const newData = await get(API_CONSTS?.GROUPS_LIST);
    dispatch(groupList(newData?.data));
  }, [dispatch]);

  React.useEffect(() => {
    fetchAllGroups();
  }, [fetchAllGroups]);

  React.useEffect(() => {
    const findData = GroupData?.find((item) => String(item.id) === String(id));
    console.log("findaata", findData);
    if (findData) {
      // setAllInputs({ ...findData, SongId: findData.SongId.split(",") });
      setAllInputs({ ...findData, SongId: findData.Group_songs });
      setInputFields([
        ...findData?.Group_songs?.map((item) => {
          return {
            order: item?.order,
            SongId: item?.Song.id,
          };
        }),
      ]);
    }
  }, [id, tracksData, GroupData]);

  const [errors, setErrors] = useState({});

  const validate = () => {
    let flag = true;
    let errorsMessages = { ...errors };

    if (!allInputs?.name || allInputs?.name === "") {
      errorsMessages = { ...errorsMessages, name: "Name is required" };
      flag = false;
    } else {
      errorsMessages = { ...errorsMessages, name: "" };
    }

    setErrors(errorsMessages);
    return flag;
  };
  const onApiResponse = () => {
    fetchGroups();
    setLoading(false);
    navigate("/groups");
  };
  const addRecord = async (data) => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("name", data?.name);
      formData.append("SongId", JSON.stringify(inputFields));

      if (id) {
        // formData.append("id", id);
        await post(API_CONSTS?.GROUPS_EDIT + id, formData).then((res) => {
          onApiResponse();
        });
      } else {
        await post(API_CONSTS?.GROUPS_ADD, formData).then((res) => {
          onApiResponse();
        });
      }
    } catch (e) {
      setLoading(false);
      console.error("Error adding document: ", e);
    }
  };

  const fetchGroups = React.useCallback(async () => {
    const newData = await get(API_CONSTS?.GROUPS_LIST);
    dispatch(groupList(newData?.data));
  }, [dispatch]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      addRecord({ ...allInputs });
    } else {
      return false;
    }
  };

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        SongId: "",
        order: "",
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    if (index < list.length) {
      const updatedSong = { ...list[index], [name]: value };
      list[index] = updatedSong;
    } else {
      // Handle adding a new song
      const newSong = { [name]: value };
      list.push(newSong);
    }
    setInputFields(list);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleFormSubmit}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        p: "10px",
      }}
    >
      <Typography variant="h5" align="center" sx={{ my: 1 }}>
        Add Group
      </Typography>
      <TextField
        id="name"
        name="name"
        label="Name"
        variant="outlined"
        value={allInputs?.name}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.name}
        sx={{ my: 2 }}
      />
      {/* <MultipleSelectTags
        name="SongId"
        label="Song IDs"
        value={allInputs.SongId}
        options={tracksData}
        onChange={(newVal) => {
          setAllInputs({ ...allInputs, SongId: newVal });
        }}
      /> */}
      {inputFields.map((data, index) => {
        return (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            key={index}
          >
            <Grid item xs={6}>
              <SingleSelectTags
                multiple={false}
                name="SongId"
                label="Song IDs"
                value={data?.SongId}
                options={tracksData}
                onChange={(value) => {
                  handleChange(index, {
                    target: { name: "SongId", value: value },
                  });
                }}
                placeholder="Select from the List"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="order"
                name="order"
                label="Order"
                value={data?.order}
                variant="outlined"
                fullWidth
                onChange={(evnt) => handleChange(index, evnt)}
                sx={{ my: 2 }}
              />
            </Grid>
            <Grid item xs={1}>
              {inputFields.length !== 1 ? (
                <IconButton
                  className="btn btn-outline-danger"
                  onClick={() => removeInputFields(index)}
                >
                  x
                </IconButton>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        );
      })}
      <Button className="btn btn-outline-success " onClick={addInputField}>
        Add New
      </Button>
      {/* <MultiSelectInput
        name="SongId"
        label="Song IDs"
        value={allInputs.SongId}
        options={tracksData}
        onChange={(newVal) => {
          setAllInputs({ ...allInputs, SongId: newVal });
        }}
      /> */}
      <div>
        <LoadingButton
          color="secondary"
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          type="submit"
        >
          {id ? "Update" : "Save"}
        </LoadingButton>
        <Button size="small" component={Link} onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </div>
    </Box>
  );
}

export default AddGroup;

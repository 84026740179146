import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function AccountMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const pageURL = window?.location?.href;
  const whatsappUrl = isMobile
    ? "whatsapp://send?text="
    : "https://web.whatsapp.com/send?text=";
  const message = `Here is link for Song you are looking for\n${pageURL}`;
  const url = `${whatsappUrl}${encodeURI(message)}&app_absent=0`;
  const user = localStorage.getItem("user");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        // message="Link Copyied to clipboard"
        // action={action}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Link Copyied to clipboard
        </Alert>
      </Snackbar>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, color: "#FFFFFF" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            window.open(url);
          }}
        >
          <WhatsAppIcon /> (એપ દ્વારા શેર કરો) Share on Whatsapp
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigator.clipboard.writeText(pageURL);
            setSnackbarOpen(true);
          }}
        >
          <FileCopyIcon /> Copy URL
        </MenuItem>
        {user && user !== "undefined" && (
          <MenuItem
            onClick={() => {
              navigate(`/song/edit/${props?.id}`);
            }}
          >
            <EditIcon /> સુધારા કરો
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}

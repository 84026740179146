export const API_CONSTS = {
  LOGIN_USER: "auth/login",
  REGISTER_USER: "users/register",
  SONGS_LIST: "songs",
  ADD_SONG: "songs/add",
  EDIT_SONG: "songs/edit",
  PRASAD_LIST: "prasadi/list",
  ADD_PRASAD_ENTRY: "prasadi/add",
  EDIT_PRASAD_ENTRY: "prasadi/edit",
  GROUPS_LIST: "groups/list",
  GROUPS: "groups/",
  GROUPS_ADD: "groups/add",
  GROUPS_EDIT: "groups/edit/",
  FEEDBACK_LIST: "feedback/list",
  FEEDBACK_ADD: "feedback/add",
  FEEDBACK_EDIT: "feedback/edit",
  VIEW_COUNT: "songs/view_count",
};

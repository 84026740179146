import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = React.useCallback(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const currentTime = Date.now() / 1000;
        const decodedTime = jwtDecode(token);

        if (currentTime > decodedTime.exp) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/");
        } else {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setIsLoggedIn(false);
        navigate("/login");
      }
    } else {
      setIsLoggedIn(false);
      navigate("/login");
    }
  }, [navigate, setIsLoggedIn]);
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn, checkUserToken]);
  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};
export default ProtectedRoute;

import { createSlice } from "@reduxjs/toolkit";

const prasadSlice = createSlice({
  name: "prasad",
  initialState: [],
  reducers: {
    prasadList(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export const { prasadList } = prasadSlice.actions;
export default prasadSlice.reducer;

import { Box } from "@mui/system";
import React from "react";
import HTMLFlipBook from "react-pageflip";
import { useSelector } from "react-redux";
function Book(props) {
  const tracksData = useSelector((state) => state.songs);
  return (
    <HTMLFlipBook width={300} height={500}>
      {tracksData?.map((track) => {
        return (
          <Box
            className="demoPage"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              p: "10px",
              textAlign: "center",
              height: "100%",
              overflowY: "auto",
              fontSize: "12px",
            }}
          >
            <h4 style={{ textAlign: "center" }}>{track?.name}</h4>
            {track?.lyrics !== "" && <pre>{track?.lyrics}</pre>}
            {track?.lyrics === "" && track?.image?.length > 0
              ? track?.image?.map((img) => (
                  <div className="page-image">
                    <img src={img} alt="" />
                  </div>
                ))
              : ""}
          </Box>
        );
      })}
    </HTMLFlipBook>
  );
}

export default Book;

import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import SearchByVoice from "../../Components/SearchByVoice/SearchByVoice";
import SongList from "./SongList";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SongTable() {
  let q = useQuery().get("q");
  const tracksData = useSelector((state) => state.songs);
  const [filteredData, setFilteredData] = React.useState(tracksData);
  const [searchTerm, setSearchTerm] = React.useState("");
  let { type } = useParams();
  const [searchParams] = useSearchParams();
  const startWith = searchParams.get("startWith");

  const filterData = React.useCallback(() => {
    let tracksDataTemp = [...tracksData];
    if (type) {
      tracksDataTemp = tracksDataTemp?.filter(
        (item) => String(item.type) === String(type)
      );
    }

    if (startWith && startWith !== "") {
      tracksDataTemp = tracksDataTemp.filter(
        (item) => item.name.substring(0, 1) === startWith
      );
    }
    if (searchTerm !== "") {
      let trimSearchTerm = searchTerm.trim();
      tracksDataTemp = tracksDataTemp.filter(
        (track) =>
          track?.id?.toString()?.indexOf(trimSearchTerm) !== -1 ||
          track?.name?.indexOf(trimSearchTerm) !== -1 ||
          track?.nameen
            ?.toLowerCase()
            ?.indexOf(trimSearchTerm?.toLowerCase()) !== -1 ||
          track?.lyrics?.indexOf(trimSearchTerm) !== -1
      );
    }

    if (q) {
      tracksDataTemp.sort(
        (a, b) => parseInt(b.view_count) - parseInt(a.view_count)
      );
    }

    setFilteredData(tracksDataTemp);
  }, [tracksData, type, startWith, searchTerm]);
  React.useEffect(filterData, [tracksData, type, startWith, q, filterData]);

  return (
    <>
      <SearchByVoice searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <SongList tracks={filteredData} />
    </>
  );
}

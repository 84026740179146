import { configureStore } from "@reduxjs/toolkit";
import songsReducer from "./songsSlice";
import prasadReducer from "./prasadSlice";
import authReducer from "./authSlice";
import loadingReducer from "./LoadingSlice";
import groupsReducer from "./groupsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    prasads: prasadReducer,
    songs: songsReducer,
    groups: groupsReducer,
    rootLoading: loadingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageGallary from "../../Components/ImageGallary/ImageGallary";
import SongActions from "../../Components/ViewSong/SongActions";

function ViewSong() {
  let { songId } = useParams();
  const navigate = useNavigate();
  const [fontSize, setFontSize] = React.useState(14);
  const tracksData = useSelector((state) => state.songs);
  const [currentTrack, setCurrentTrack] = React.useState({});
  const [highlightedLine, setHighlightedLine] = React.useState(null);
  const lines = currentTrack?.lyrics?.split("\r\n");
  const decreaseFontsize = () => {
    if (fontSize >= 8) {
      setFontSize(fontSize - 1);
    }
  };
  React.useEffect(() => {
    if (Array.isArray(tracksData)) {
      const findTrack = tracksData?.find(
        (item) => String(item.id) === String(songId)
      );

      if (findTrack) {
        const imagesArray = findTrack?.image?.split(",");
        if (findTrack?.image?.length > 0) {
          setCurrentTrack({
            ...findTrack,
            image: [
              ...imagesArray.map(
                (item) => process.env.REACT_APP_ASSETS_BASE_URL + item
              ),
            ],
          });
        } else {
          setCurrentTrack({
            ...findTrack,
          });
        }
      }
    } else {
      console.error("tracksData is not an array");
    }
  }, [songId, tracksData]);

  return (
    <>
      <Grid
        container
        wrap="false"
        justifyContent="space-between"
        spacing={1}
        px="12px"
        mb="12px"
        sx={{ color: "#FFFFFF" }}
      >
        <Grid item>
          <Link
            to="/list"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </Link>
        </Grid>
        <Grid item textAlign={"center"}>
          <Typography varient="h4" component="h4" fontSize="16px">
            {currentTrack?.name}{" "}
            {currentTrack?.nameen && <small>({currentTrack?.nameen})</small>}
          </Typography>
        </Grid>
        <Grid item textAlign={"right"}>
          <SongActions id={currentTrack.id} />
        </Grid>
      </Grid>

      {currentTrack?.id ? (
        <>
          {currentTrack?.lyrics && (
            <>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  px: "10px",
                }}
              >
                <Typography variant="h6" textAlign={"center"}>
                  Lyrics
                </Typography>
                <Divider />
                <ButtonGroup
                  disableElevation
                  size="small"
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                  alignItems="center"
                  sx={{
                    float: "right",
                    boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                    m: "2px",
                  }}
                >
                  <Button onClick={decreaseFontsize} disabled={fontSize <= 7}>
                    -
                  </Button>
                  <Box
                    sx={{
                      px: "10px",
                      py: "3px",
                    }}
                  >
                    {fontSize}
                  </Box>
                  <Button
                    onClick={() => {
                      setFontSize(fontSize + 1);
                    }}
                  >
                    +
                  </Button>
                </ButtonGroup>

                <Box
                  textAlign="center"
                  fontSize={`${fontSize}px`}
                  fontWeight="600"
                  py="20px"
                  px="10px"
                  width="90vw"
                  overflow="auto"
                  sx={{ maxWidth: "100%" }}
                >
                  <code>
                    <pre>
                      {lines?.map((line, index) => (
                        <span
                          key={index}
                          className={
                            highlightedLine === index ? "highlighted" : ""
                          }
                          onClick={() => setHighlightedLine(index)}
                        >
                          {line}
                          <br />
                        </span>
                      ))}
                      {/* {currentTrack?.lyrics || (
                    <small>Lyrics not found for this track</small>
                  )} */}
                    </pre>
                  </code>
                </Box>
              </Box>{" "}
            </>
          )}
          {currentTrack?.image?.length > 0 && (
            <>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  px: "10px",
                  pb: "10px",
                  mt: "12px",
                }}
              >
                <Typography variant="h6" textAlign={"center"}>
                  Images
                </Typography>
                <Divider />
                <Typography textAlign={"center"}>
                  {currentTrack?.image?.length > 0 ? (
                    <ImageGallary previewFile={currentTrack?.image} />
                  ) : (
                    <small>Image not found for this track</small>
                  )}
                </Typography>{" "}
              </Box>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default ViewSong;

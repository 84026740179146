import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import { Button, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_CONSTS } from "../../config/constants";
import { get, post } from "../../config/http-common";

function AddFeedback() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const [allInputs, setAllInputs] = useState({
    name: "",
    mobile: "",
    email: "",
    comment: "",
  });
  const fetchData = async () => {
    let findData = await get(API_CONSTS?.FEEDBACK_LIST);
    findData = findData?.data;
    if (findData) {
      setAllInputs(findData);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, [id]);

  const [errors, setErrors] = useState({});

  const validate = () => {
    let flag = true;
    let errorsMessages = { ...errors };

    if (!allInputs?.name || allInputs?.name === "") {
      errorsMessages = { ...errorsMessages, name: "Name is required" };
      flag = false;
    } else {
      errorsMessages = { ...errorsMessages, name: "" };
    }
    if (!allInputs?.mobile || allInputs?.mobile === "") {
      errorsMessages = { ...errorsMessages, mobile: "Mobile is required" };
      flag = false;
    } else {
      errorsMessages = { ...errorsMessages, mobile: "" };
    }

    if (!allInputs?.email || allInputs?.email === "") {
      errorsMessages = { ...errorsMessages, email: "email is required" };
      flag = false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(allInputs.email)
    ) {
      errorsMessages = { ...errorsMessages, email: "Invalid email" };
      flag = false;
    } else {
      errorsMessages = { ...errorsMessages, email: "" };
    }
    if (!allInputs?.comment || allInputs?.comment === "") {
      errorsMessages = { ...errorsMessages, comment: "comment is required" };
      flag = false;
    } else {
      errorsMessages = { ...errorsMessages, comment: "" };
    }
    setErrors(errorsMessages);
    return flag;
  };
  const onApiResponse = () => {
    setLoading(false);
    navigate("/");
  };
  const addRecord = async (data) => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("name", data?.name);
      formData.append("mobile", data?.mobile);
      formData.append("email", data?.email);
      formData.append("comment", data?.comment);

      if (id) {
        formData.append("id", id);
        await post(API_CONSTS?.FEEDBACK_EDIT, formData).then((res) => {
          onApiResponse();
        });
      } else {
        await post(API_CONSTS?.FEEDBACK_ADD, formData).then((res) => {
          onApiResponse();
        });
      }
    } catch (e) {
      setLoading(false);
      console.error("Error adding document: ", e);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      addRecord({ ...allInputs });
    } else {
      return false;
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleFormSubmit}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        p: "10px",
      }}
    >
      <Typography variant="h5" align="center" sx={{ my: 1 }}>
        Add Feedback
      </Typography>
      <TextField
        id="name"
        name="name"
        label="Name"
        variant="outlined"
        value={allInputs?.name}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.name}
        sx={{ my: 2 }}
      />
      <TextField
        id="mobile"
        name="mobile"
        label="Mobile"
        variant="outlined"
        value={allInputs?.mobile}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={(e) => {
          const result = e.target.value.replace(/\D/g, "");
          setAllInputs({ ...allInputs, [e.target.name]: result });
        }}
        helperText={errors?.mobile}
        sx={{ my: 2 }}
      />
      <TextField
        id="email"
        name="email"
        label="email"
        type="email"
        variant="outlined"
        value={allInputs?.email}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.email}
        sx={{ my: 2 }}
      />
      <TextField
        id="comment"
        name="comment"
        label="comment"
        variant="outlined"
        multiline
        value={allInputs?.comment}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.comment}
        sx={{ my: 2 }}
      />

      <div>
        <LoadingButton
          color="secondary"
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          type="submit"
        >
          {id ? "Edit" : "Save"}
        </LoadingButton>
        <Button size="small" component={Link} onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </div>
    </Box>
  );
}

export default AddFeedback;

import { createSlice, current } from "@reduxjs/toolkit";
import { post } from "../config/http-common";

const authSlice = createSlice({
  name: "auth",
  initialState: [],
  reducers: {
    loginUser: async (state, action) => {
      const postData = {
        username: "admin",
        password: "admin",
      };
      const login = await post("auth/login", postData);
      console.log("login data", action, login?.data?.token);
      const newstate = { ...current(state), token: "a" }; // + login?.data?.token;
      return newstate;
    },
  },
});

export const { loginUser } = authSlice.actions;
export default authSlice.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SearchByVoice from "../../Components/SearchByVoice/SearchByVoice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, List, ListItem } from "@mui/material";
import GroupSongView from "./GroupSongView";
// import SongList from "../Songs/SongList";
import { API_CONSTS } from "../../config/constants";
import { get } from "../../config/http-common";
import { groupList } from "../../Redux/groupsSlice";
import GroupSongs from "../Songs/GroupSongs";

export default function GroupSongList() {
  const dispatch = useDispatch();
  const groupsData = useSelector((state) => state.groups);
  let { id } = useParams();
  const navigate = useNavigate();
  const findData = groupsData?.find((item) => String(item.id) === String(id));
  const tracksData = findData?.Group_songs;
  const [filteredData, setFilteredData] = React.useState(findData?.Group_songs);

  const fetchGroups = React.useCallback(async () => {
    const newData = await get(API_CONSTS?.GROUPS_LIST);
    dispatch(groupList(newData?.data));
  }, [dispatch]);

  React.useEffect(() => {
    if (groupsData.length <= 0) {
      fetchGroups();
    }
    const findDatain = groupsData?.find(
      (item) => String(item.id) === String(id)
    );
    if (findDatain) {
      setFilteredData(findDatain.songs);
    }
  }, [id, groupsData, fetchGroups]);

  const [searchTerm, setSearchTerm] = React.useState("");
  const [viewAllHere, setViewAllHere] = React.useState(false);
  let { type } = useParams();
  const [searchParams] = useSearchParams();
  const startWith = searchParams.get("startWith");
  const filterData = React.useCallback(() => {
    let tracksDataTemp = tracksData;
    console.log("terackstemdata", tracksDataTemp);
    if (type) {
      tracksDataTemp = tracksDataTemp?.filter(
        (item) => String(item.type) === String(type)
      );
    }

    if (startWith && startWith !== "") {
      tracksDataTemp = tracksDataTemp.filter(
        (item) => item.name.substring(0, 1) === startWith
      );
    }
    if (searchTerm !== "") {
      let trimSearchTerm = searchTerm.trim();
      tracksDataTemp = tracksDataTemp.filter(
        (track) =>
          track?.Song.id?.toString().indexOf(trimSearchTerm) !== -1 ||
          track?.Song.name?.indexOf(trimSearchTerm) !== -1 ||
          track?.Song.nameen
            ?.toLowerCase()
            ?.indexOf(trimSearchTerm?.toLowerCase()) !== -1 ||
          track?.Song.lyrics?.indexOf(trimSearchTerm) !== -1
      );
    }
    setFilteredData(tracksDataTemp);
  }, [tracksData, type, startWith, searchTerm]);
  React.useEffect(filterData, [tracksData, type, startWith, filterData]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Link
          to="/list"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
        </Link>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setViewAllHere(!viewAllHere)}
        >
          View {viewAllHere ? "List" : "All"}
        </Button>
      </Box>
      {viewAllHere ? (
        <>
          <List>
            {filteredData?.length > 0 ? (
              filteredData?.map((item) => {
                if (item?.id) {
                  return <GroupSongView key={item.id} item={item} />;
                } else {
                  return "";
                }
              })
            ) : (
              <ListItem
                alignItems="flex-start"
                flexWrap="wrap"
                disableGutters
                sx={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                No Track found
              </ListItem>
            )}
          </List>
        </>
      ) : (
        <>
          <SearchByVoice
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <GroupSongs tracks={filteredData} />
        </>
      )}
    </>
  );
}

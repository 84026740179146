import { Card, IconButton, TextField } from "@mui/material";
import React from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
function SearchByVoice({ searchTerm, setSearchTerm }) {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  React.useEffect(() => {
    setSearchTerm(transcript);
  }, [transcript, setSearchTerm]);
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
  return (
    <React.StrictMode>
      <Card
        sx={{
          position: "sticky",
          top: "70px",
          marginTop: "10px",
          marginBottom: "10px",
          zIndex: "10",
          padding: "5px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <>
          <TextField
            id="seachitem"
            label="Search"
            value={searchTerm}
            sx={{ marginBottom: "10px" }}
            fullWidth={true}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <>
                  {listening === true || searchTerm !== "" ? (
                    <IconButton
                      size="small"
                      aria-label="Clear"
                      onClick={() => {
                        resetTranscript();
                        setSearchTerm("");
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                  <IconButton
                    size="small"
                    onClick={() => {
                      listening
                        ? SpeechRecognition.stopListening()
                        : SpeechRecognition.startListening({ language: "gu" });
                    }}
                  >
                    {listening ? <MicOffIcon /> : <MicIcon />}
                  </IconButton>
                </>
              ),
            }}
            variant="standard"
          />
        </>
      </Card>
    </React.StrictMode>
  );
}

export default SearchByVoice;

/*
Design Credits : https://dribbble.com/shots/6437042-Music-App-UI
Also I couldn't find any open music API
so credits to 
https://codepen.io/JavaScriptJunkie/details/qBWrRyg
to make his repo public and make the songs easily accesible
also I used his UI as inspiration too
*/

import React from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ReplayIcon from "@mui/icons-material/Replay";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "./PlayerCardStyled.css";
import { tracks } from "../../config/songlist";

const player = new Audio(tracks[0].source);
player.setAttribute("preload", "metadata");
const userOptions = React.createContext({
  shuffle: false,
  repeat: false,
});

function Options(props) {
  let options = React.useContext(userOptions);
  let [shuffl, setShuffle] = React.useState(options.shuffle);
  let [repet, setRepeat] = React.useState(options.repeat);
  let [fav, setFav] = React.useState(tracks[props.idx].favorited);

  React.useEffect(
    () => setFav(tracks[props.idx].favorited),
    [setFav, props.idx]
  );

  function shuffle() {
    options.shuffle = !options.shuffle;
    options.repeat = false;
    setShuffle(!shuffl);
    setRepeat(false);
  }

  function repeat() {
    options.repeat = !options.repeat;
    options.shuffle = false;
    setShuffle(false);
    setRepeat(!repet);
  }

  function favorite() {
    tracks[props.idx].favorited = !tracks[props.idx].favorited;
    setFav(tracks[props.idx].favorited);
  }

  function openURL() {
    window.open(tracks[props.idx].url, "_blank");
  }

  return (
    <div className="options">
      {(shuffl && (
        <button onClick={shuffle} className="opt" style={{ color: "#147CC0" }}>
          <SwapHorizIcon />
        </button>
      )) || (
        <button onClick={shuffle} className="opt">
          <SwapHorizIcon />
        </button>
      )}
      <button className="opt" onClick={openURL}>
        <LaunchIcon />
      </button>
      {(fav && (
        <button onClick={favorite} className="opt" style={{ color: "#147CC0" }}>
          <FavoriteBorderIcon />
        </button>
      )) || (
        <button onClick={favorite} className="opt">
          <FavoriteIcon />
        </button>
      )}
      {(repet && (
        <button onClick={repeat} className="opt" style={{ color: "#147CC0" }}>
          <ReplayIcon />
        </button>
      )) || (
        <button onClick={repeat} className="opt">
          <ReplayIcon />
        </button>
      )}
    </div>
  );
}

function Control(props) {
  return (
    <div className="controls">
      <button
        className="controlButton"
        onClick={(x) => props.setIdx(props.idx - 1 < 0 ? 8 : props.idx - 1)}
      >
        <SkipPreviousIcon />
      </button>
      {props.playState === true ? (
        <button
          className="centerButton"
          onClick={(x) => props.setPlayState(false)}
        >
          <PauseIcon />
        </button>
      ) : (
        <button
          className="centerButton"
          onClick={(x) => props.setPlayState(true)}
        >
          <PlayArrowIcon />
        </button>
      )}
      <button
        className="controlButton"
        onClick={(x) => props.setIdx((props.idx + 1) % 9)}
      >
        <SkipNextIcon />
      </button>
    </div>
  );
}

function Progress(props) {
  let [currLength, setCurrLength] = React.useState(0);
  let [length, setLength] = React.useState(0);
  let options = React.useContext(userOptions);
  const progressBar = document.querySelector(".progressBar");

  function updateProgress(e) {
    let offset = e.target.getBoundingClientRect().left;
    let newOffSet = e.clientX;
    let newWidth = newOffSet - offset;
    progressBar.style.width = newWidth + "px";
    let secPerPx = length / 280;
    player.currentTime = secPerPx * newWidth;
  }

  setInterval(() => {
    setLength(Math.ceil(player.duration));
    setCurrLength(Math.ceil(player.currentTime));
    let secPerPx = Math.ceil(player.duration) / 280;
    let newWidth = player.currentTime / secPerPx;
    if(document.querySelector(".progressBar")){
      document.querySelector(".progressBar").style.width = newWidth + "px";
    }
    if (player.currentTime === player.duration) {
      if (options.shuffle === true) {
        props.setIdx(parseInt(Math.random() * 1000) % 9);
      } else if (options.repeat === true) {
        player.play();
      } else {
        props.setIdx((props.idx + 1) % 9);
      }
    }
  }, 1000);

  function formatTime(s) {
    return Number.isNaN(s)
      ? "0:00"
      : (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
  }

  return (
    <div className="progress">
      <div className="currentTime">
        <p>{formatTime(currLength)}</p>
      </div>
      <div className="progressCenter" onClick={(e) => updateProgress(e)}>
        <div className="progressBar"></div>
      </div>
      <div className="songLength">
        <p>{formatTime(length)}</p>
      </div>
    </div>
  );
}

function Avatar(props) {
  return (
    <>
      <h4 className="name">{tracks[props.idx].artist}</h4>
      <h1 className="title">{tracks[props.idx].name}</h1>
    </>
  );
}

function Container() {
  let [idx, setIdx] = React.useState(0);
  let [playState, setPlayState] = React.useState(false);
  let oldIdx = React.useRef(idx);
  React.useEffect(() => {
    if (playState === true) player.play();
    else player.pause();
    if (idx !== oldIdx.current) {
      player.pause();
      player.src = tracks[idx].source;
      player.load();
      player.play();
      setPlayState(true);
      oldIdx.current = idx;
    }
  }, [playState, idx, setPlayState]);

  return (
    <div className="playerContaier">
      <Avatar idx={idx} />
      <Progress setIdx={setIdx} idx={idx} />
      <Control
        setIdx={setIdx}
        idx={idx}
        playState={playState}
        setPlayState={setPlayState}
      />
      <Options setIdx={setIdx} idx={idx} />
    </div>
  );
}

function Header() {
  return (
    <div className="header">
      <button className="icon">
        <ChevronLeftIcon />
      </button>
      <h1 className="headerText">Song</h1>
      <button className="icon">
        <MoreHorizIcon />
      </button>
    </div>
  );
}

// root method
function PlayerCard() {
  return (
    <div className="playerContainer">
      <div className="container">
        <Header />
        <Container />
      </div>
    </div>
  );
}
export default PlayerCard;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import { useDispatch, useSelector } from "react-redux";
import { songList } from "../../Redux/songsSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../config/http-common";
import { API_CONSTS } from "../../config/constants";
import { Button, FormControl, InputLabel, Typography } from "@mui/material";
import ImageGallary from "../../Components/ImageGallary/ImageGallary";

function EditSong() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allInputs, setAllInputs] = useState({
    name: "",
    nameen: "",
    summary: "",
    image: "",
    lyrics: "",
    type: 0,
    status: 1,
    view_count: 0,
  });
  const [errors, setErrors] = useState({});
  // const [imageAsFile, setImageAsFile] = useState("");
  const [previewFile, setPreviewFile] = useState("");
  let { songId } = useParams();
  const tracksData = useSelector((state) => state.songs);
  React.useEffect(() => {
    async function fetchData() {
      const findTrack = await tracksData?.find(
        (item) => String(item.id) === String(songId)
      );
      if (findTrack) {
        setAllInputs(findTrack);
        if (findTrack?.image) {
          // setPreviewFile([
          //   ...findTrack?.image?.map(
          //     (item) => process.env.REACT_APP_API_BASE_URL + "/" + item
          //   ),
          // ]);

          // setPreviewFile([...findTrack?.image?.split(",")]);
          setPreviewFile(
            findTrack?.image
              ?.split(",")
              .map((item) => process.env.REACT_APP_ASSETS_BASE_URL + "/" + item)
          );
        }
      }
    }
    fetchData();
  }, [songId, tracksData]);

  const validate = () => {
    let flag = true;
    let errorsMessages = { ...errors };

    if (!allInputs?.name || allInputs?.name === "") {
      errorsMessages = { ...errorsMessages, name: "Name is required" };
      flag = false;
    } else {
      errorsMessages = { ...errorsMessages, name: "" };
    }
    if (!allInputs?.summary || allInputs?.summary === "") {
      errorsMessages = { ...errorsMessages, summary: "Summary is required" };
      flag = false;
    } else {
      errorsMessages = { ...errorsMessages, summary: "" };
    }

    setErrors(errorsMessages);
    return flag;
  };
  const editRecord = async (data) => {
    setLoading(true);
    try {
      const imageString =
        typeof data?.image !== "string" ? data?.image.join(",") : data?.image;
      let formData = new FormData();
      formData.append("id", allInputs?.id);
      formData.append("name", data?.name);
      formData.append("nameen", data?.nameen);
      formData.append("summary", data?.summary);
      formData.append("lyrics", data?.lyrics);
      // for (let i = 0; i < imageAsFile.length; i++) {
      //   formData.append("image[]", imageAsFile[i]);
      // }
      formData.append("image", imageString);
      formData.append("type", data?.type);
      formData.append("status", data?.status);
      formData.append("view_count", data?.view_count);

      await post(API_CONSTS?.EDIT_SONG + "/" + songId, formData, {
        // await post("songs/" + songId, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        fetchSongs();
        setLoading(false);
        // navigate(`/list/view/${res?.data?.id}`);
        navigate("/list");
      });
    } catch (e) {
      setLoading(false);
      console.error("Error adding document: ", e);
    }
  };

  const fetchSongs = React.useCallback(async () => {
    const newData = await get(API_CONSTS?.SONGS_LIST);
    dispatch(songList(newData?.data));
  }, [dispatch]);

  const handleFireBaseUpload = async (e) => {
    e.preventDefault();
    if (validate()) {
      editRecord({ ...allInputs });
    } else {
      return false;
    }
  };

  const handleImageAsFile = async (e) => {
    setLoading(true);
    setAllInputs((prevInputs) => ({ ...prevInputs, lyrics: "" }));
    const formData = new FormData();
    const files = e.target.files;
    if (!files) return;
    const previewFiles = [];
    for (let i = 0; i < files.length; i++) {
      formData.append("image[]", files[i]);
      previewFiles.push(URL.createObjectURL(files[i]));
    }
    // setImageAsFile(files);
    setPreviewFile(previewFiles);
    await fetch(
      process.env.REACT_APP_API_FILE_READER_BASE_URL +
        "/" +
        API_CONSTS?.SONGS_LIST +
        "/img-to-txt",
      {
        method: "POST",
        body: formData,
      }
    )
      .then(async (response) => {
        return await response.json();
      })
      .then((res) => {
        setAllInputs((prevInputs) => ({
          ...prevInputs,
          // lyrics: allInputs.lyrics + "\r\n" + res.textContent,
          lyrics:
            // allInputs.lyrics +
            // "\r\n" +
            res.data.map((item) => item.textContent + "\r\n"),
          image: res.images,
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleFireBaseUpload}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        p: "10px",
      }}
    >
      <Typography variant="h5" align="center" sx={{ my: 1 }}>
        Edit Song
      </Typography>
      <TextField
        id="name"
        name="name"
        label="Name"
        variant="outlined"
        value={allInputs?.name}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.name}
        sx={{ my: 2 }}
      />
      <TextField
        id="nameen"
        name="nameen"
        label="Name English"
        variant="outlined"
        value={allInputs?.nameen}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.nameen}
        sx={{ my: 2 }}
      />
      <TextField
        id="summary"
        name="summary"
        label="Summary"
        variant="outlined"
        value={allInputs?.summary}
        InputLabelProps={{ shrink: true }}
        multiline
        fullWidth
        rows={2}
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.summary}
        sx={{ my: 2 }}
      />
      <TextField
        id="lyrics"
        name="lyrics"
        label="Lyrics"
        variant="outlined"
        value={allInputs?.lyrics}
        InputLabelProps={{ shrink: true }}
        multiline
        fullWidth
        rows={6}
        onChange={(e) => {
          setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
        }}
        helperText={errors?.lyrics}
      />
      <FormControl variant="outlined" fullWidth sx={{ my: 2 }}>
        <Button variant="contained" component="label">
          Upload File
          <input type="file" onChange={handleImageAsFile} hidden multiple />
        </Button>
        <ImageGallary previewFile={previewFile} />
      </FormControl>
      <FormControl variant="outlined" fullWidth sx={{ my: 2 }}>
        <InputLabel id="type">Type</InputLabel>
        <Select
          labelId="type"
          id="type"
          name="type"
          value={allInputs?.type}
          onChange={(e) => {
            setAllInputs({ ...allInputs, [e.target.name]: e.target.value });
          }}
          label="Type"
        >
          <MenuItem value="0">
            <em>Type</em>
          </MenuItem>
          <MenuItem value={1}>આરતી</MenuItem>
          <MenuItem value={2}>છંદ</MenuItem>
          <MenuItem value={3}>ગરબા</MenuItem>
          <MenuItem value={4}>ભજન</MenuItem>
          <MenuItem value={6}>સ્તુતિ</MenuItem>
          <MenuItem value={5}>other</MenuItem>
        </Select>
      </FormControl>
      <div>
        <LoadingButton
          color="secondary"
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          type="submit"
        >
          Edit
        </LoadingButton>
        <Button size="small" component={Link} onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </div>
    </Box>
  );
}

export default EditSong;

import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Container,
  Typography,
  TextField,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate, Navigate } from "react-router-dom";
import { API_CONSTS } from "../../config/constants";
import { post } from "../../config/http-common";

export default function Register() {
  const navigate = useNavigate();
  const [error, setError] = React.useState({});
  const [isSignedIn, setIsSignIn] = React.useState(false);
  React.useEffect(() => {
    let user = localStorage.getItem("user", true);
    setIsSignIn(Boolean(user));
  }, []);

  const isEmailValid = (email) => {
    // A simple email format validation, you may want to enhance it
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    const data = new FormData(event.currentTarget);
    console.log("role", data.get("role"));
    const postData = {
      username: data.get("username"),
      email: data.get("email"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      password: data.get("password"),
      confirmPassword: data.get("confirmPassword"),
      title: data.get("title"),
      role: isSignedIn ? data.get("role") : "User",
    };

    const newErrors = {};

    // Robust form validation
    if (postData.username.length < 3) {
      newErrors.username = "Username must be at least 3 characters long";
    }

    if (postData.email.length === 0 || !isEmailValid(postData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (postData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    if (postData.confirmPassword !== postData.password) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (postData.firstName.length === 0) {
      newErrors.firstName = "First name is required";
    }

    if (postData.lastName.length === 0) {
      newErrors.lastName = "Last name is required";
    }

    if (postData.title.length === 0) {
      newErrors.title = "Title is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    await post(API_CONSTS?.REGISTER_USER, postData)
      .then((res) => {
        console.log("res", res);
        // navigate(0);
      })
      .catch((error) => {
        console.log("errors", error);
        setError(error?.response?.data);
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          p: "10px",

          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        {Object.keys(error).length > 0 && (
          <Box>
            {Object.entries(error).map(([field, message]) => (
              <Typography key={field} component="small" color="red">
                {message}
              </Typography>
            ))}
          </Box>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username_1"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email_1"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="confirmPassword"
            id="confirmPassword"
            autoComplete="current-confirmPassword"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="firstName_1"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lastName_1"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="title"
            label="Title"
            type="title"
            id="title"
            autoComplete="current-title"
          />
          {isSignedIn && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="role"
              label="Role"
              type="role"
              id="role"
              autoComplete="current-role"
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";

function getStyles(item, activeitem) {
  return item === activeitem
    ? {
        borderRadius: "10px",
        p: "20px",
        my: "20px",
        width: "100%",
        height: "100%",
        display: "flex",
        position: "relative",
        overflow: "auto",
        zIndex: 1,
      }
    : {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 0 10px #000",
        px: "10px",
        py: "10px",
        position: "absolute",
        overflow: "hidden",
        fontSize: "4px",
        right: "0",
        top: "-60px",
        width: "80px",
        height: "70px",
        cursor: "pointer",
        zIndex: 11111,
      };
}

function GroupSongView({ item }) {
  const [viewMode, setViewMode] = React.useState(
    item?.lyrics !== "" ? "lyrics" : item?.image !== "" ? "image" : ""
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          px: "10px",
          my: "20px",
        }}
      >
        <Typography variant="h6" textAlign={"center"} p={1}>
          {item?.name}
        </Typography>

        <Divider />
        <div style={{ position: "relative", minHeight: "320px" }}>
          {item?.lyrics !== "" && (
            <Box
              sx={{
                transitionProperty: "width, height",
                transitionDuration: "0.6s",
                transitionTimingFunction: "linear",
                fontWeight: "bold",
                ...getStyles("lyrics", viewMode),
              }}
              onClick={() => setViewMode("lyrics")}
            >
              <code>
                <pre>
                  {item?.lyrics || (
                    <small>Lyrics not found for this track</small>
                  )}
                </pre>
              </code>
            </Box>
          )}
          {item?.image !== "" && (
            <Box
              sx={{
                transitionProperty: "width, height",
                transitionDuration: "0.6s",
                transitionTimingFunction: "linear",
                ...getStyles("image", viewMode),
              }}
              onClick={() => setViewMode("image")}
            >
              {item?.image ? (
                <img
                  src={process.env.REACT_APP_API_BASE_URL + item?.image}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              ) : (
                ""
              )}
            </Box>
          )}
        </div>
      </Box>
    </>
  );
}

export default GroupSongView;

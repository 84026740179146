import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SideNav from "../../Components/SideNav/SideNav";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import { songList } from "../../Redux/songsSlice";
import { get } from "../../config/http-common";
// import { loginUser } from "../../Redux/authSlice";
import { API_CONSTS } from "../../config/constants";
import { useNavigate } from "react-router";
import ScrollTop from "../ScrollTop";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "../../hooks/use-dimensions";
import { MenuToggle } from "../SideNav/MenuToggle";
import { Container } from "@mui/material";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.primary.light,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  })
);

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
// }));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export default function Layout() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = React.useRef(null);
  const { height } = useDimensions(containerRef);

  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchSongs = React.useCallback(async () => {
    // const token = localStorage.getItem("token");
    // if (!token) {
    //   const postData = {
    //     username: "admin",
    //     password: "admin",
    //   };
    //   const login = await post(API_CONSTS?.LOGIN_USER, postData);
    //   localStorage.setItem("token", login?.data?.token);
    //   // dispatch(loginUser());
    // }
    const newData = await get(API_CONSTS?.SONGS_LIST).catch((e) => {
      if (e?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate(0);
      }
    });
    dispatch(songList(newData?.data));
  }, [dispatch, navigate]);

  React.useEffect(() => {
    fetchSongs();
  }, [fetchSongs]);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "primary.light",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <CssBaseline />

      {/* <AppBar position="relative"> */}
      <Toolbar>
        {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton> */}
        <Typography
          flex="1"
          textAlign="center"
          variant="h6"
          noWrap
          component="div"
          color="#FFFFFF"
          fontWeight="bold"
        >
          ગરબા મંડળ
        </Typography>
      </Toolbar>
      {/* </AppBar> */}

      <motion.nav
        className="sidenavbar"
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <motion.div className="background" variants={sidebar} />
        <SideNav open={isOpen} setOpen={toggleOpen} />
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.nav>
      <Main>
        <div id="back-to-top-anchor" />
        <DrawerHeader />
        <Container disableGutters>
          <Outlet />
        </Container>
        <ScrollTop>
          <Fab size="small" aria-label="ટોચ પર પાછા સ્ક્રોલ કરો">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </Main>
    </Box>
  );
}

import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function MultipleSelectTags(props) {
  return (
    <Stack spacing={3}>
      <Autocomplete
        multiple={false}
        id={props?.name}
        options={props?.options}
        getOptionLabel={(option) => option.name}
        // defaultValue={props?.value || []}
        value={props?.options?.find((item) => item?.id === props?.value)}
        filterSelectedOptions
        // value={props ?.options?.filter((item) =>
        //   props?.value?.includes(item.id)
        // )}
        onChange={(event, newValue) => {
          props.onChange(newValue?.id);
        }}
        renderInput={(params) => (
          <TextField {...params} label={props?.label} placeholder="Favorites" />
        )}
      />
    </Stack>
  );
}
